.carousel {
    position: relative;
    overflow: hidden;
    $slides: 5;
    $delay: 40s; // Time to cycle everything
    >* {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        @for $i from 1 through $slides {
            &:nth-child(#{$i}) {
                animation: fade#{$i} $delay infinite;
            }
            @keyframes fade#{$i} {
                @for $j from 0 through $slides {
                    #{percentage($j/$slides)} {
                        opacity: if($i==$j+1 or ($i==1 and $j==$slides), 1, 0);
                    }
                }
            }
        }
    }
}

.glow-box {
    margin: 1em;
    height: 50vmin;
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #ccc;
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(from var(--border-angle), #213, #112 5%, #112 60%, #213 95%);
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, hsl(22, 100%, 46%) 99%, transparent);
    background: // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box, // border-box extends this background to the border space
    var(--gradient-border) border-box, // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
        to {
            --border-angle: 1turn;
        }
    }
    &:hover {
        animation-play-state: paused;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}