.container {
    width: 80%;
}

.circle-container {
    $particleWidth: 5px;
    $particleNum: 20;
    $particleColor: hsl(22, 100%, 80%);
    position: absolute;
    transform: translateY(-10vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: screen;
        background-image: radial-gradient( hsl(22, 100%, 46%), hsl(19, 100%, 43%) 10%, hsla(27, 91%, 21%, 0.596) 56%);
        animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
        @keyframes fade-frames {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0.7;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes scale-frames {
            0% {
                transform: scale3d(0.4, 0.4, 1);
            }
            50% {
                transform: scale3d(2.2, 2.2, 1);
            }
            100% {
                transform: scale3d(0.4, 0.4, 1);
            }
        }
    }
    @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
            $circleSize: random($particleWidth);
            width: $circleSize + px;
            height: $circleSize + px;
            $startPositionY: random(10) + random(100);
            $framesName: "move-frames-" + $i;
            $moveDuration: 7000 + random(4000) + ms;
            animation-name: #{$framesName};
            animation-duration: $moveDuration;
            animation-delay: random(11000) + ms;
            @keyframes #{$framesName} {
                from {
                    transform: translate3d( #{random(100) + vw}, #{$startPositionY + vh}, 0);
                }
                to {
                    transform: translate3d( #{random(100) + vw}, #{- $startPositionY - random(30) + vh}, 0);
                }
            }
            .circle {
                animation-delay: random(4000) + ms;
            }
        }
    }
}