#Hiperbite {
    /* Sections */
    .section {
        height: 100vh;
        min-height: 500px;
        position: relative;
    }
    /* Containers */
    .content-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        clip: rect(auto, auto, auto, auto);
        pointer-events: none;
    }
    .content-inner {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        padding: 0;
        z-index: 99;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform;
        -webkit-perspective: 1000;
        perspective: 1000;
        pointer-events: all;
    }
    .content-center {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h4 {
            max-width: 800px;
            text-align: left;
        }
    }
    /* Aesthetics */
    .top-section {
        background: linear-gradient(135deg, #be4701 0%, #f15c12 100%);
    }
    .middle-section {
        background: linear-gradient(135deg, #000 0%, #1a1a1a 100%);
    }
    .bottom-section {
        background: linear-gradient(135deg, #d4fef2 0%, #ffffff 100%);
    }
    .section h2 {
        font-size: 12vw;
        font-weight: 900;
        padding: 0.2em;
    }
    .section p,
    .section a {
        font-weight: 700;
        text-align: center;
        font-size: 22px;
    }
    .content-theme-dark h2 {
        color: #f15c12;
        text-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
    }
    .content-theme-dark p,
    .content-theme-dark h4 {
        color: #ffbba4;
    }
    .content-theme-light h2 {
        color: #062930;
        text-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
    }
    .content-theme-light p,
    .content-theme-light h4 {
        color: #728e93;
    }
}